import { SVGProps, Ref, forwardRef } from 'react'

const SvgCheckSimple = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.694 7.28a1 1 0 0 1 .026 1.414l-8.666 9a1 1 0 0 1-1.441 0l-4.333-4.5a1 1 0 1 1 1.44-1.388l3.613 3.752 7.947-8.252a1 1 0 0 1 1.414-.026Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgCheckSimple)
export default ForwardRef
